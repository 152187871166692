<template>
    <div class="flex flex-1 flex-col space-y-1 pt-5 p-4">
        <GridVehicleScrollbar :vehicle="vehicle" />

        <div class="text-base font-semibold text-gray-900">
            <DealerMeNuxtLink
                :href="`/for-sale/${vehicle.slug}`"
                @click="updatePosition"
            >
                <!--                    <span aria-hidden="true" class="absolute inset-0" /> -->
                <template v-if="vehicle.custom_name">{{
                    vehicle.custom_name
                }}</template>
                <template v-else>
                    {{ vehicle.year }} {{ vehicle.make }}
                    {{ vehicle.model }}
                </template>
            </DealerMeNuxtLink>
        </div>
        <div class="text-sm text-gray-500 inline-flex items-center">
            <span v-if="!vehicle.custom_name && vehicle.trim">
                {{ vehicle.trim }}
            </span>
            <span v-if="vehicle.miles">
                <span v-if="!vehicle.custom_name && vehicle.trim">&nbsp;- </span
                >{{ formatMiles(vehicle.miles) }}
                {{ t('miles') }}
            </span>
            <VehicleElectricIcon :vehicle="vehicle" />
            <!--                <VehicleElectricIcon -->
            <!--                    :vehicle="vehicleWithCarfax" -->
            <!--                    @click=" -->
            <!--                        modalStore.openModal({ -->
            <!--                            component: 'ElectricVehicleModal', -->
            <!--                            title: 'Electric Vehicle Types', -->
            <!--                            params: { -->
            <!--                                vehicle: props.vehicle, -->
            <!--                            }, -->
            <!--                        }) -->
            <!--                    " -->
            <!--                /> -->
        </div>
        <div class="text-sm text-gray-500">#{{ vehicle.stock }}</div>
        <div class="flex gap-x-2">
            <GridVehiclePrice :vehicle="vehicle" />
            <GridVehiclePayment
                v-if="domainStore.payments.enabled && vehicle.price.selling > 0"
                :vehicle="vehicle"
            />
        </div>

        <div
            v-if="warranty"
            class="flex w-full items-center justify-between text-base"
        >
            <span class="flex items-center">
                <ShieldCheckIcon class="h-5 w-5 mr-1" />
                <span>Warranty</span>
                <QuestionMarkCircleIcon
                    class="h-5 w-5 ml-1 cursor-pointer"
                    @click="
                        modalStore.openModal({
                            component: 'CertifiedWarrantyVehicleModal',
                            title: warranty.title,
                            size: ModalSize.SM,
                            icon: {
                                component: ShieldCheckIcon,
                                bgClasses: 'bg-green-100',
                                iconClasses: 'h-6 w-6 text-green-600',
                            },
                            params: {
                                vehicle: props.vehicle,
                                description: warranty.description,
                            },
                        })
                    "
                />
            </span>
        </div>

        <VehicleHistory
            v-if="storyblokStore.srpHistoryEnabled && showHistory"
            :type="CTAButtonComponentType.SRP"
            :vehicle="vehicle"
        />

        <VehicleCTAButtons
            v-if="srp && storyblokStore.settingsLoaded"
            :type="CTAButtonComponentType.SRP"
            :vehicle="vehicle"
            :incentives="activeOrganizationIncentives"
            @click="openLeadModal"
        />
    </div>
    <!--        <ElectricVehicleModal -->
    <!--            :modal-open="electricVehicleModalOpen" -->
    <!--            :vehicle="vehicleWithCarfax" -->
    <!--            @close="electricVehicleModalOpen = false" -->
    <!--        /> -->
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import { ShieldCheckIcon } from '@heroicons/vue/24/solid'
import { QuestionMarkCircleIcon } from '@heroicons/vue/24/outline'
import {
    CTAButtonComponentType,
    type LeadButtonData,
} from '~/utils/vdpLeadComponentSchema'
import GridVehicleScrollbar from '~/components/SearchResultsPage/Vehicle/GridVehicle/GridVehicleScrollbar.vue'
import GridVehiclePrice from '~/components/SearchResultsPage/Vehicle/GridVehicle/GridVehiclePrice/GridVehiclePrice.vue'
import GridVehiclePayment from '~/components/SearchResultsPage/Vehicle/GridVehicle/GridVehiclePayment.vue'
import VehicleCTAButtons from '~/components/Vehicle/VehicleCTAButtons.vue'
import VehicleHistory from '~/components/VehicleDescriptionPage/VehicleHistory/Index.vue'
import VehicleElectricIcon from '~/components/Vehicle/VehicleElectricIcon.vue'
import { LeadModalComponent } from '~/utils/types/leadModal'
import { VehicleUnlockType } from '~/utils/types/vehicleUnlock/enums'
import { trackCtaInteractionEvent } from '~/composables/asc/events/CtaInteraction'
import type { Vehicle, VehicleIncentive } from '~/utils/types/inventory'
import {
    ASCElementSubType,
    ASCElementType,
    ASCEventAction,
    ASCEventActionResult,
    ASCPageType,
} from '~/utils/types/asc/enums'
import { formatMiles, getSetting } from '~/utils/functions'
import { ModalSize } from '~/utils/types/global/enums'

const storyblokStore = useStoryblokStore()
const vehicleUnlockStore = useVehicleUnlockStore()
const leadModalStore = useLeadModalStore()
const domainStore = useDomainStore()
const inventoryStore = useInventoryStore()
const modalStore = useModalStore()

const props = defineProps({
    vehicle: {
        type: Object as PropType<Vehicle>,
        required: true,
    },
    srp: {
        type: Boolean,
        default: false,
    },
    showHistory: {
        type: Boolean,
        default: true,
    },
    position: {
        type: Number,
        default: null,
    },
})

// const electricVehicleModalOpen = ref(false)

const { t } = useI18n()

const activeOrganizationIncentives = computed(() =>
    props.srp
        ? (domainStore.autoZnetworkEntity.incentives ?? []).filter(
              (incentive: VehicleIncentive) => incentive.active,
          )
        : [],
)

const warrantySetting =
    computed(() =>
        getSetting(
            domainStore.autoZnetworkSettings,
            'inventory.certified.warranty',
        ),
    ) ?? null

const warranty = computed(() => {
    if (
        !warrantySetting.value ||
        !warrantySetting.value.description ||
        !props.vehicle.certified
    ) {
        return null
    }

    return {
        title: warrantySetting.value.title ?? 'Limited warranty',
        description: warrantySetting.value.description,
    }
})

function updatePosition() {
    if (props.position) {
        inventoryStore.resultsPosition = props.position
    }
}

function openLeadModal(button: LeadButtonData) {
    if (button.component === LeadModalComponent.VEHICLE_INCENTIVE) {
        vehicleUnlockStore.openModal(
            VehicleUnlockType.INCENTIVE,
            props.vehicle,
            button.incentive,
            button.formButtonText ?? null,
        )
    } else {
        leadModalStore.openModal(CTAButtonComponentType.SRP, button, {
            vehicle: props.vehicle,
        })
    }

    trackCtaInteractionEvent({
        pageType: props.srp ? ASCPageType.ITEM_LIST : ASCPageType.ITEM,
        data: {
            element_text: button.asc.text || null,
            element_color: button.asc.color || null,
            element_type: ASCElementType.ITEM_DETAILS,
            element_subtype: ASCElementSubType.CTA_BUTTON,
            event_action: ASCEventAction.CLICK,
            event_action_result: ASCEventActionResult.OPEN,
        },
        vehicle: props.vehicle,
    })
}
</script>
