<template>
    <div class="w-full relative fade-r-10">
        <div
            class="relative w-full flex gap-x-1.5 snap-x overflow-x-auto scrollbar-hidden pr-8"
            dir="ltr"
        >
            <div
                v-if="vehicle.condition"
                class="scroll-ms-6 snap-start flex-shrink-0 inline-flex items-center rounded-md bg-blue-50 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10"
            >
                {{ vehicle.condition }}
            </div>
            <a
                v-if="windowSticker"
                :href="windowSticker"
                target="_blank"
                class="scroll-ms-6 snap-start flex-shrink-0 inline-flex items-center rounded-md bg-white px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-700/10 cursor-pointer"
            >
                <DocumentTextIcon class="mr-0.5 w-4 h-4" />
                Sticker
            </a>
            <div
                v-for="(featuredOption, index) in featuredOptions"
                :key="index"
                class="scroll-ms-6 snap-start flex-shrink-0 inline-flex items-center rounded-md bg-white px-2 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-700/10"
            >
                {{ featuredOption }}
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import { DocumentTextIcon } from '@heroicons/vue/16/solid'
import type { Vehicle } from '~/utils/types/inventory'

const props = defineProps({
    vehicle: {
        type: Object as PropType<Vehicle>,
        required: true,
    },
})

const windowSticker = computed(() => props.vehicle.vehicle_make_sticker_url)

const featuredOptions = computed<string[]>(() =>
    (props.vehicle.featured_options_sorted ?? []).slice(0, 10),
)
</script>
