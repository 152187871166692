<template>
    <div v-if="autoCheckEnabled || showCarfax">
        <div v-if="type === CTAButtonComponentType.VDP" class="h2">
            {{ t('history') }}
        </div>
        <div v-if="vehicle" class="grid grid-cols-3 gap-x-2 gap-y-4">
            <div
                v-if="autoCheckEnabled"
                class="flex items-center justify-center rounded-lg border bg-white bg-opacity-25 shadow-sm"
                :class="type === CTAButtonComponentType.SRP ? 'p-2' : 'p-4'"
            >
                <a
                    :href="`/autocheck/${vehicle.id}`"
                    target="_blank"
                    class="focus:outline-none cursor-pointer"
                    :class="
                        type === CTAButtonComponentType.SRP ? 'max-w-32' : ''
                    "
                    @click="
                        addCTAEvent(
                            'autocheck',
                            'AutoCheck Report',
                            `/autocheck/${vehicle.id}`,
                        )
                    "
                >
                    <img
                        src="/assets/autocheck_logo.png"
                        alt="AutoCheck Badge"
                    />
                </a>
            </div>
            <div
                v-if="showCarfax"
                class="flex items-center justify-center rounded-lg border bg-white bg-opacity-25 shadow-sm"
                :class="
                    type === CTAButtonComponentType.SRP ? 'py-0.5 px-2' : 'p-4'
                "
            >
                <a
                    :href="carfaxLink.url"
                    target="_blank"
                    class="w-full"
                    :class="
                        type === CTAButtonComponentType.SRP ? 'max-w-32' : ''
                    "
                    @click="
                        addCTAEvent(
                            'carfax',
                            'Carfax Vehicle History Report',
                            carfaxLink.url,
                        )
                    "
                >
                    <img
                        class="focus:outline-none cursor-pointer"
                        :class="{
                            'carfax-snapshot-hover': carfaxApiData?.snapshotKey,
                        }"
                        :data-snapshotkey="carfaxApiData?.snapshotKey"
                        :src="carfaxLink.iconUrl"
                        :alt="carfaxLink.valueBadgeAltText"
                    />
                </a>
            </div>

            <div
                v-if="type === CTAButtonComponentType.SRP"
                class="flex items-center justify-center py-0.5 px-2"
            >
                <VehicleCargurus
                    v-if="
                        domainStore.cargurusEnabled &&
                        vehicle.price.selling &&
                        vehicle.price.selling > 0
                    "
                    :vehicle="vehicle"
                    :height="40"
                />
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import { getSetting } from '@/utils/functions'
import type { CarfaxLink, Vehicle } from '~/utils/types/inventory'
import { CTAButtonComponentType } from '~/utils/vdpLeadComponentSchema'
import VehicleCargurus from '~/components/VehicleDescriptionPage/VehicleResearchTools/VehicleCargurus.vue'
import { trackCtaInteractionEvent } from '~/composables/asc/events/CtaInteraction'
import {
    ASCElementSubType,
    ASCElementType,
    ASCEventAction,
    ASCEventActionResult,
    ASCPageType,
} from '~/utils/types/asc/enums'

const { t } = useI18n()

const props = defineProps({
    type: {
        type: String as PropType<CTAButtonComponentType>,
        default: CTAButtonComponentType.VDP,
    },
    vehicle: {
        type: Object as PropType<Vehicle>,
        required: true,
    },
})

const domainStore = useDomainStore()

const autoCheckEnabled = computed(() => {
    const autoCheckSettings = getSetting(
        domainStore.autoZnetworkSettings,
        'vendor.autocheck',
    )

    const enabled = autoCheckSettings?.enabled ?? false
    const sid = autoCheckSettings?.sid ?? null

    return enabled && sid !== null
})

const carfaxApiData = computed(() => {
    const isEnabled = getSetting(
        domainStore.autoZnetworkSettings,
        'vendor.carfax.enabled',
    )

    if (!isEnabled) {
        return null
    }

    return props.vehicle.carfax ?? null
})

const carfaxLink = computed(
    () => carfaxApiData.value?.carfaxLink ?? ({} as CarfaxLink),
)

const showCarfax = computed(
    () => !props.vehicle.carfax_disabled && carfaxApiData.value,
)

function addCTAEvent(affiliation: string, product: string, url: string) {
    trackCtaInteractionEvent({
        pageType:
            props.type === CTAButtonComponentType.SRP
                ? ASCPageType.ITEM_LIST
                : ASCPageType.ITEM,
        data: {
            element_type: ASCElementType.POPUP,
            element_subtype: ASCElementSubType.CTA_BUTTON,
            event_action: ASCEventAction.CLICK,
            event_action_result: ASCEventActionResult.OPEN,
            link_url: url,
            product_name: product,
            affiliation,
        },
        vehicle: props.vehicle,
    })
}
</script>
