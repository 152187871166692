<template>
    <Menu as="div" class="relative inline-block text-left">
        <div>
            <MenuButton
                class="w-full text-left rounded-lg bg-green-700 px-2 py-1 text-white shadow-sm ring-1 ring-inset ring-green-600 hover:bg-green-600"
            >
                <div
                    class="text-xs text-green-100 tracking-tight leading-tight"
                >
                    {{ t('monthly') }}
                </div>
                <div class="flex items-center font-bold">
                    <span class="text-xl tracking-tight">
                        ${{ estimatedPayment }}
                    </span>
                    <ChevronDownIcon
                        class="-mr-1 h-5 w-5 text-white"
                        aria-hidden="true"
                    />
                </div>
            </MenuButton>
        </div>

        <transition
            enter-active-class="transition ease-out duration-100"
            enter-from-class="transform opacity-0 scale-95"
            enter-to-class="transform opacity-100 scale-100"
            leave-active-class="transition ease-in duration-75"
            leave-from-class="transform opacity-100 scale-100"
            leave-to-class="transform opacity-0 scale-95"
        >
            <MenuItems
                class="absolute right-0 z-50 mt-2 w-72 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
            >
                <div class="p-4 flex flex-col space-y-2">
                    <div class="tracking-tight font-bold text-gray-900">
                        {{
                            parseLocaleTemplates(t('srp_payment_calc_title'), {
                                amount: estimatedPayment,
                            })
                        }}
                    </div>
                    <div class="text-gray-600 text-sm">
                        {{
                            parseLocaleTemplates(
                                t('srp_payment_calc_description'),
                                {
                                    termLength:
                                        paymentCalculatorStore.currentTermLength,
                                    apr: aprFormatted,
                                    downPayment: downAmount.toLocaleString(),
                                },
                            )
                        }}
                    </div>
                    <div>
                        <DealerMeNuxtLink
                            :href="
                                localePath(
                                    `/finance-application?vehicle=${vehicle.id}`,
                                )
                            "
                            class="block btn w-full text-center !px-3.5 !py-2.5"
                        >
                            {{ t('srp_payment_calc_button') }}
                        </DealerMeNuxtLink>
                    </div>
                </div>
            </MenuItems>
        </transition>
    </Menu>
</template>

<script setup lang="ts">
import { Menu, MenuButton, MenuItems } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import type { PropType } from 'vue'
import type { Vehicle } from '~/utils/types/inventory'
import { parseLocaleTemplates } from '~/utils/functions'

const domainStore = useDomainStore()
const paymentCalculatorStore = usePaymentCalculatorStore()
const localePath = useLocalePath()

const props = defineProps({
    vehicle: {
        type: Object as PropType<Vehicle>,
        required: true,
    },
})

const { t } = useI18n()

const downPercentage = computed(
    () => (domainStore.payments.defaults.downPercentage ?? 10) / 100,
)

const price = computed(() => props.vehicle.price.selling ?? 0)

const aprFormatted = computed(() =>
    parseFloat((paymentCalculatorStore.currentAPR * 100).toFixed(2)),
)

const downAmount = computed(() => Math.ceil(price.value * downPercentage.value))

const estimatedPayment = computed(() => {
    if (price.value === 0) {
        return null
    }

    const payment = paymentCalculatorStore.getEstimatedMonthlyPayment(
        price.value,
        price.value * downPercentage.value,
    )

    if (payment) {
        return numberFormat(payment)
    }

    return null
})
</script>
