import type { Vehicle, VehicleIncentive } from '~/utils/types/inventory'
import { LeadModalComponent } from '~/utils/types/leadModal'
import leadSchema, {
    type LeadButtonData,
    type LeadComponentTemplateData,
} from '~/utils/vdpLeadComponentSchema'
import type { StoryblokLeadButton } from '~/utils/types/storyblok'

export const useBuildLeadComponentData = (
    vehicle: Vehicle,
    component: LeadModalComponent,
    incentives: VehicleIncentive[] | null = null,
    storyblokData: StoryblokLeadButton | null = null,
): LeadButtonData | null => {
    const schema: LeadComponentTemplateData = leadSchema[component]

    if (!schema) {
        return null
    }

    const componentType: LeadModalComponent = storyblokData?.type || component
    const vehicleIncentiveId: string | null =
        storyblokData?.vehicle_incentive_id ?? null

    const incentive: VehicleIncentive | null =
        vehicleIncentiveId !== null &&
        componentType === LeadModalComponent.VEHICLE_INCENTIVE
            ? ((incentives ?? []).find(
                  (incentive: VehicleIncentive) =>
                      incentive.id.toString() === vehicleIncentiveId,
              ) ?? null)
            : null

    const ctaButtonText: string =
        storyblokData?.cta_button_text ||
        incentive?.title ||
        schema.defaults.ctaButtonText
    const title: string = storyblokData?.title || schema.defaults.title
    const description: string =
        storyblokData?.description || schema.defaults.description
    const formButtonText: string =
        storyblokData?.button_text || schema.defaults.formButtonText
    const ascText: string =
        storyblokData?.asc_text || incentive?.title || schema.defaults.ascText
    const ascColor: string =
        storyblokData?.asc_color || schema.defaults.ascColor

    return {
        component: componentType,
        source: schema.source,
        sold: storyblokData?.sold || false,
        ctaButtonText: parseTemplate(vehicle, ctaButtonText),
        title: parseTemplate(vehicle, title),
        description: parseTemplate(vehicle, description),
        formButtonText: parseTemplate(vehicle, formButtonText),
        asc: {
            text: parseTemplate(vehicle, ascText),
            color: parseTemplate(vehicle, ascColor),
        },
        incentive,
        icon: schema.icon ?? null,
    } as LeadButtonData
}

function parseTemplate(vehicle: any, str: string): string {
    const searchArr: string[] | null = str.match(/\[(.*?)\]/g)

    if (searchArr) {
        searchArr.forEach((searchStr: string) => {
            if (searchStr.includes('vehicle.')) {
                const vehicleVariable: any = searchStr
                    .replace(/[[\]]+/g, '')
                    .split('.')[1]
                const replaceVariable: any = vehicle[vehicleVariable]

                if (replaceVariable) {
                    str = str.replace(searchStr, replaceVariable)
                }
            }
        })
    }

    return str
}
