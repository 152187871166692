<template>
    <div>
        <div v-if="vehicle.sold" class="text-3xl font-bold tracking-tight">
            Sold
        </div>
        <template v-else>
            <div
                v-if="
                    !hideRetailPrice &&
                    (vehiclesHaveBeenUnlocked || !hidePrices) &&
                    vehicle.price?.retail &&
                    vehicle.price?.selling &&
                    vehicle.price?.retail > vehicle.price?.selling
                "
                :class="['tracking-tight line-through', sizeClasses.retail]"
            >
                <span class="sr-only">Retail Price</span>
                <span> ${{ numberFormat(vehicle.price?.retail) }} </span>
            </div>
            <div :class="['font-bold tracking-tight', sizeClasses.selling]">
                <template
                    v-if="
                        !vehiclesHaveBeenUnlocked &&
                        unlockPrices &&
                        vehicle.price?.selling
                    "
                >
                    <button
                        v-if="unlockType === 'button'"
                        type="button"
                        class="btn w-full"
                        @click="
                            vehicleUnlockStore.openModal(
                                VehicleUnlockType.PRICE,
                                vehicle,
                            )
                        "
                    >
                        {{ t('unlock_price') }}
                    </button>
                    <span
                        v-else
                        class="cursor-pointer"
                        @click="
                            vehicleUnlockStore.openModal(
                                VehicleUnlockType.PRICE,
                                vehicle,
                            )
                        "
                    >
                        {{ t('unlock_price') }}
                    </span>
                </template>

                <span
                    v-else-if="
                        (vehiclesHaveBeenUnlocked || !hidePrices) &&
                        vehicle.price?.selling
                    "
                    >${{ numberFormat(vehicle.price.selling) }}</span
                >
                <ClickToCallLink
                    v-else-if="phoneNumber"
                    :phone="phoneNumber"
                    :department="ASCDepartment.SALES"
                >
                    {{ callForPriceText }}
                </ClickToCallLink>
                <span v-else>{{ callForPriceText }}</span>
            </div>
        </template>

        <!--        <div v-if="price_callout" class="mt-1"> -->
        <!--            <div class="text-xs flex items-center"> -->
        <!--                <InformationCircleIcon class="h-3 w-3 mr-0.5" /> -->
        <!--                <span>{{ price_callout }}</span> -->
        <!--            </div> -->
        <!--        </div> -->
    </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import type { Vehicle } from '~/utils/types/inventory'
import { VehicleUnlockType } from '~/utils/types/vehicleUnlock/enums'
import ClickToCallLink from '~/components/Shared/ClickToCallLink.vue'
import { ASCDepartment } from '~/utils/types/asc/enums'
import { numberFormat } from '~/utils/functions'

const domainStore = useDomainStore()
const storyblokStore = useStoryblokStore()
const vehicleUnlockStore = useVehicleUnlockStore()
const locationStore = useLocationStore()

const props = defineProps({
    vehicle: {
        type: Object as PropType<Vehicle>,
        required: true,
    },
    size: {
        type: String as PropType<'small' | 'medium' | 'large'>,
        default: 'medium',
    },
    unlockType: {
        type: String as PropType<'text' | 'button'>,
        default: 'button',
    },
    hideRetailPrice: {
        type: Boolean,
        default: false,
    },
})

const { t } = useI18n()

const hidePrices = computed(
    () => domainStore.inventorySettings?.hidePrices ?? false,
)
const unlockPrices = computed(
    () => domainStore.inventorySettings?.unlockPrices ?? false,
)
const vehiclesHaveBeenUnlocked = computed(
    () => useCookie('vu').value ?? useCookie('pu').value ?? false,
)

const callForPriceText = computed(
    () => storyblokStore.callForThePriceText || t('call_for_price'),
)

const sizeSchema = {
    small: {
        retail: 'text-xs',
        selling: 'text-base',
    },
    medium: {
        retail: 'text-sm',
        selling: 'text-xl',
    },
    large: {
        retail: 'text-base font-extrabold',
        selling: 'text-3xl',
    },
}

const sizeClasses = computed(() => sizeSchema[props.size])

const phoneNumber = computed(
    () =>
        locationStore.currentLocation?.phone_number ??
        domainStore.autoZnetworkOrganization?.phone_number ??
        null,
)
</script>
