<template>
    <div
        ref="intersectionHolder"
        class="group relative flex flex-col rounded-lg border border-gray-200 bg-white sm:hover:shadow-lg shadow-sm"
    >
        <div class="z-40 absolute top-0 left-0 p-2 text-sm flex gap-x-1">
            <div
                v-if="vehicle.deposited_at"
                class="inline-flex items-center rounded-md bg-yellow-400 px-2 py-1 text-xs font-semibold text-yellow-800 ring-1 ring-inset ring-yello-700/10"
            >
                <LockClosedIcon class="h-4 w-4" />
                <span class="ml-1">{{ t('on_hold') }}</span>
            </div>
            <div
                v-if="vehicle.images.length > 0"
                class="inline-flex items-center rounded-md bg-gray-50 px-2 py-1 text-xs font-semibold text-gray-700 ring-1 ring-inset ring-gray-700/10"
            >
                <CameraIcon class="h-4 w-4" />
                <span class="ml-1">{{ vehicle.images.length }}</span>
            </div>
        </div>
        <div class="relative">
            <GridVehicleMedia
                :vehicle="vehicle"
                :first-image="firstImage"
                :show-tag-line="showTagLine"
                :position="position"
                :intersected="intersected"
            />
            <GridVehicleMenu :vehicle="vehicle" />
        </div>

        <GridVehicleDetails
            :vehicle="vehicleWithCarfax"
            :srp="srp"
            :show-history="showHistory"
            :position="position"
        />

        <GridVehicleLocation :vehicle="vehicle" />

        <!--        <div v-if="srp && vehicleVisitedRecently" class="p-2">-->
        <!--            <div>-->
        <!--                <span class="font-semibold text-sm">Last Visit:</span>-->
        <!--                {{-->
        <!--                    dayjs(vehicleVisitedRecently.updated).format(-->
        <!--                        'M/DD/YY h:mm A',-->
        <!--                    )-->
        <!--                }}-->
        <!--                ({{ dayjs(vehicleVisitedRecently.updated).fromNow(true) }})-->
        <!--            </div>-->
        <!--            <div>-->
        <!--                <span class="font-semibold text-sm">Count:</span>-->
        <!--                {{ vehicleVisitedRecently.count }}-->
        <!--            </div>-->
        <!--        </div>-->
    </div>
</template>

<script setup lang="ts">
import type { PropType } from 'vue'
import { LockClosedIcon, CameraIcon } from '@heroicons/vue/24/solid'
import type { Vehicle, VehicleImage } from '~/utils/types/inventory'
import { onIntersect } from '~/composables/intersectionObserver'
import GridVehicleDetails from '~/components/SearchResultsPage/Vehicle/GridVehicle/GridVehicleDetails.vue'
import GridVehicleLocation from '~/components/SearchResultsPage/Vehicle/GridVehicle/GridVehicleLocation.vue'
import GridVehicleMedia from '~/components/SearchResultsPage/Vehicle/GridVehicle/Media/GridVehicleMedia.vue'
import GridVehicleMenu from '~/components/SearchResultsPage/Vehicle/GridVehicle/GridVehicleMenu.vue'
// import dayjs from 'dayjs'
// import { useThemeStore } from '@/Stores/theme'
// import Logo from '@/Pages/Tenant/Components/Logo.vue'

// const themeStore = useThemeStore()
const inventoryStore = useInventoryStore()
// const visitorStore = useVisitorStore()

const props = defineProps({
    vehicle: {
        type: Object as PropType<Vehicle>,
        required: true,
    },
    position: {
        type: Number,
        default: null,
    },
    showTagLine: {
        type: Boolean,
        default: true,
    },
    showHistory: {
        type: Boolean,
        default: true,
    },
    srp: {
        type: Boolean,
        default: false,
    },
    intersected: {
        type: Boolean,
        default: false,
    },
    carfaxData: {
        type: [Array, null] as PropType<any[] | null>,
        default: null,
    },
})

const emit = defineEmits(['intersected', 'unintersected'])

const { t } = useI18n()

const { isMobile } = useDevice()

const intersectionHolder = ref<HTMLDivElement>()

onMounted(() =>
    nextTick(() => {
        if (isMobile && intersectionHolder.value) {
            onIntersect({
                element: intersectionHolder.value,
                callback: () => emit('intersected', props.vehicle.id),
                outCallback: () => emit('unintersected', props.vehicle.id),
                threshold: 0.7,
            })
        }
    }),
)

// const vehicleVisitedRecently = computed(() =>
//     visitorStore.vehicleViewedRecently(props.vehicle.id, 7),
// )

const carfaxDataArr = computed(() =>
    props.carfaxData !== null ? props.carfaxData : inventoryStore.srpCarfaxData,
)

const vehicleWithCarfax = computed<Vehicle>(() => {
    if (carfaxDataArr.value.length === 0) {
        return props.vehicle
    }

    const findCarfax = Array.isArray(carfaxDataArr.value)
        ? carfaxDataArr.value.find(
              (carfaxObj: any) => carfaxObj.id === props.vehicle.id,
          )
        : null

    return {
        ...props.vehicle,
        ...{ carfax: findCarfax?.carfax ?? null },
    }
})

const firstImage = computed<VehicleImage | null>(() =>
    vehicleWithCarfax.value.images ? vehicleWithCarfax.value.images[0] : null,
)
</script>
